<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item >
					<el-input v-model="params.keyword" placeholder="请输入姓名/手机号码/电子邮箱/身份证/银行卡/现居地址" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择岗位" v-model="params.position" filterable  multiple collapse-tags>
						<el-option v-for="position in employees.positions" :key="position.id" :label="position.name" :value="position.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable clearable>
						<el-option label="入职时间" value="hired"></el-option>
						<el-option label="转正时间" value="positive"></el-option>
						<el-option label="离职时间" value="separated"></el-option>
						<el-option label="创建时间" value="created"></el-option>
						<el-option label="更新时间" value="updated"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getEmployees({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="employee_dialog = true;">添加员工</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="employee_table" class="bill scroll-wrapper absolute employees" height="100%" :data="tables" :size="theme.size" :row-class-name="tableRowClassName">
				<!-- <el-table-column type="expand">
					<template slot-scope="props">
						<el-descriptions style="padding: 0 20px;" v-if="props.row.enrollment">
							<el-descriptions-item label="真实姓名">{{ props.row.enrollment.name }}</el-descriptions-item>
							<el-descriptions-item label="手机号">18100000000</el-descriptions-item>
							<el-descriptions-item label="居住地">{{ props.row.enrollment.current_address }}</el-descriptions-item>
						</el-descriptions>
						<el-empty description="暂未填写入职登记" v-else></el-empty>
					</template>
				</el-table-column> -->
				<el-table-column label="分公司" prop="shop.name" v-if="!admin_user.shop_id"></el-table-column>
				<el-table-column label="真实姓名" min-width="100">
					<el-link slot-scope="scope" type="primary" @click="employee = {...scope.row}; employee_dialog = true;">{{scope.row.name}}</el-link>
				</el-table-column>
				<el-table-column label="联系方式" min-width="120">
					<template slot-scope="scope">
						<p v-if="scope.row.mobile"><i class="el-icon-mobile"></i> {{scope.row.mobile}}</p>
						<p v-if="scope.row.email"><i class="el-icon-message"></i> {{scope.row.email}}</p>
					</template>
				</el-table-column>
				<el-table-column label="系统账户" prop="user.name" min-width="140">
					<template slot-scope="scope">
						<template v-if="scope.row.user">
							<el-link type="primary" @click="$refs['show_user'].open(scope.row.user)">{{scope.row.user.name}}</el-link>
							<div>
								<el-tag size="mini" style="margin-right: 5px;" v-for="role in scope.row.user.roles" :key="role.id">{{role.name}}<template v-if="!admin_user.shop_id">@{{role.shop ? role.shop.name : theme.group }}</template></el-tag>
							</div>
						</template>
						<el-link type="primary" @click="createUser(scope.row)" :disabled="!$utils.create($api.URI_USERS)" v-else>创建账户</el-link>
					</template>
				</el-table-column>
				<el-table-column label="OA账户部门"  min-width="120">
					<template slot-scope="scope">
						<template v-if="oa_users[scope.row.id]">
							<p>{{oa_users[scope.row.id].name}}</p>
							<p>{{scope.row | format_department(oa_users, oa_departments)}}</p>
						</template>
						<el-link type="primary" @click="createOA(scope.row)" :disabled="scope.row.separated_at" v-else>点击关联</el-link>
					</template>
				</el-table-column>
				<el-table-column label="任职岗位" prop="shop_position.name" min-width="70"></el-table-column>
				<el-table-column label="基金保险" min-width="70" :formatter="insurances"></el-table-column>
				<el-table-column label="薪酬配置" min-width="100">
					<el-link slot-scope="scope" type="primary" @click="$refs['config'].open(scope.row)">{{scope.row | format_configs}}</el-link>
				</el-table-column>
				<template v-if="theme.width >= 1240">
					<el-table-column label="入职时间" min-width="92">
						<template slot-scope="scope">{{scope.row.hired_at | format_at}}</template>
					</el-table-column>
					<el-table-column label="转正时间" min-width="92">
						<template slot-scope="scope">{{scope.row.positive_at | format_at}}</template>
					</el-table-column>
					<el-table-column label="离职时间" min-width="92">
						<template slot-scope="scope">{{scope.row.separated_at | format_at}}</template>
					</el-table-column>
					<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
					<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
				</template>
				<el-table-column label="相关时间" min-width="160" v-else>
					<template slot-scope="scope">
						<p v-if="scope.row.hired_at">入职 {{scope.row.hired_at | format_at}}</p>
						<p v-if="scope.row.positive_at">转正 {{scope.row.positive_at | format_at}}</p>
						<p v-if="scope.row.separated_at">离职 {{scope.row.separated_at | format_at}}</p>
						<p v-if="scope.row.updated_at">跟新 {{scope.row.updated_at}}</p>
						<p v-if="scope.row.created_at">创建 {{scope.row.created_at}}</p>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getEmployees({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getEmployees({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
		<el-dialog width="480px" :title="employee.id ? '编辑员工' : '创建员工'" :visible.sync="employee_dialog" @closed="employee = {}" append-to-body destroy-on-close>
			<div style="height: 100%; overflow: auto; padding-right: 10px;">
				<el-form label-width="100px" ref="employee_form" :model="employee" :rules="employee_rules" :size="theme.size" status-icon>
					<el-form-item label="真实姓名" prop="name" v-if="employee.id">{{employee.name}}</el-form-item>
					<el-form-item label="真实姓名" prop="name" v-else>
						<el-input v-model="employee.name" placeholder="请输入名称" clearable>
							<el-select slot="prepend" style="width: 100px;" v-if="!admin_user.shop_id" v-model="employee.shop_id" placeholder="城市站点" filterable clearable>
								<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="mobile">
						<el-input v-model="employee.mobile" placeholder="请输入手机号码" clearable></el-input>
					</el-form-item>
					<el-form-item label="任职岗位" prop="shop_position_id">
						<el-select placeholder="请选择岗位" v-model="employee.shop_position_id">
							<el-option v-for="position in employees.positions" :key="position.id" :label="position.name" :value="position.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('employee_form')">{{employee.id ? '提交保存' : '提交创建'}}</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<show-user ref="show_user" @refresh="getEmployees(params)" :roles="roles"></show-user>
		<configs ref="config" @refresh="getEmployees(params)"></configs>
	</div>
</template>

<style>
	.el-table.employees .warning-row {
		background: oldlace;
	}

	.el-table.employees .success-row {
		background: #f0f9eb;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTable from '@/components/main-table';
	import showUser from '@/pages/urm/show.vue';
	import configs from './configs';

	export default {
		components: {
			mainTable,
			showUser,
			configs
		},
		computed: {
			...mapState(['theme', 'admin_user', 'employees', 'oa_users', 'oa_departments']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			},
			format_department ({ id }, users, departments) {
				if (!users[id]) return '未关联OA';
				const { main_department } = users[id];
				if (!departments[main_department]) return '未知部门';
				return departments[main_department].name;
			},
			format_configs ({salary_configs}) {
				let amount = 0;
				for (let welfare of salary_configs) {
					if (welfare.salary_field && welfare.salary_field.type == 1) {
						amount += welfare.amount;
					}
				}
				return +(amount/100).toFixed(2) + ' 元';
			}
		},
		methods: {
			tableRowClassName({row}) {
				if (row.separated_at) return 'warning-row';
				if (row.hired_at) return 'success-row';
				return '';
			},
			insurances ({insurance_funds}) {
				let a = 0;
				let b = 0;
				const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
				for (let insurance of insurance_funds) {
					const { insurance_temp } = insurance;
					if (insurance_temp) {
						if (insurance_temp.type == 1) {
							a += 1;
						}
						if (insurance_temp.type == 2) {
							b += 1;
						}
					}
				}
				if (!a && !b) return '无';
				if (!a) return `${chineseNumbers[b]}金`;
				if (!b) return `${chineseNumbers[a]}险`;
				return `${chineseNumbers[a]}险${chineseNumbers[b]}金`;
			},
			async getEmployees (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.shops = result.shops;
				this.roles = result.roles;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleEmployees (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES, {params, headers: { loading }});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				return this.$message.success(msg);
			},
			async submitEmployee (data) {
				const res = await this.$http.post(this.$api.URI_EMPLOYEES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getEmployees(this.params);
						this.employee_dialog = false;
					}
				});
			},
			createOA ({id, mobile}) {
				if (!mobile) return this.$message.error('员工暂未绑定手机号码');
				this.$confirm('请确保<b style="color: #c7000a;">' + mobile + '</b>手机号已存在OA通讯录<br />若出错的次数超出企业规模人数的20%，会导致1天不可调用。', '关联OA系统', {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleEmployees({action: 'oaid', id});
				});
			},
			createUser ({id, mobile}) {
				if (!mobile) return this.$message.error('员工暂未绑定手机号码');
				this.$confirm('默认使用员工登记手机号创建<br/>初始密码为手机号后6位', '创建系统登录账户', {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.post(this.$api.URI_USERS, {id, action: 'create', type: 'employee_id'});
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.getEmployees({...this.params});
						}
					});
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'employee_form':
							this.submitEmployee(this.employee);
						break;
					}
				});
			}
		},
		data() {
			const checkMobile = (rule, value, callback) => {
				const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
				if (value && !reg.test(value)) return callback(new Error('请输入正确的手机号'));
				return callback();
			}
			return {
				employee: {},
				roles: [],
				tables: [],
				shops: [],
				employee_dialog: false,
				params: {
					perPage: 10,
				},
				employee_rules: {
					name: [{ required: true, message: '必须填写真实姓名', trigger: 'blur'}],
					mobile: [
						{ required: true, message: '必须填写手机号码', trigger: 'blur'},
						{ validator: checkMobile, trigger: ['blur', 'change'] }
					],
					shop_position_id: [{ required: true, message: '必须填写任职岗位', trigger: 'blur'}]
				}
			}
		},
		created () {
			this.getEmployees(this.params, true);
		}
	};
</script>